import { AppLocale, AppTranslation } from './i18n.types';

export const noAppLocale: AppLocale = {
  [AppTranslation.appTitle]: 'lilo',
  [AppTranslation.headTitle]: 'I dag er det {ts, date, ::yyyyMMdd}',
  [AppTranslation['navbar.item.overview']]: 'Oversikt',
  [AppTranslation['navbar.item.objects']]: 'Dine objekter',
  [AppTranslation['navbar.item.settings']]: 'Innstillinger',
  [AppTranslation['navbar.item.FAQ']]: 'Kundeservice',
  [AppTranslation['navbar.item.logOut']]: 'Logg ut',
  [AppTranslation['navbar.user.profile-settings']]: 'Profilinnstillinger',
  [AppTranslation['navbar.user.change-company']]: 'Bytt konto',
  [AppTranslation['navbar.user.all-companies']]: 'Alle kunder',
  [AppTranslation['app-tour.title.welcome']]: 'Velkommen til LILO',
  [AppTranslation['app-tour.button.end-introduction']]: 'Avslutt introduksjon',
  [AppTranslation['app-tour.content.overview']]:
    'Som driftsansvarlig for en heis er det mye å holde styr på, og det er ikke alltid like lett å ha oversikt over alt som foregår til enhver tid. LILO gir deg enkelt oversikt over alle aktiviteter i driftsfasen av anlegget og er lett tilgjengelig der du befinner deg.',
  [AppTranslation['app-tour.content.objects']]:
    'Uavhengig om du skal dokumentere gjennomført sikkerhetskontroll eller nødvendig ettersynsarbeid er det viktig med enkel tilgang til det aktuelle objektet. Med LILO får du en digital loggbok for hvert objekt som gjør det enkelt å loggføre eller søke opp i eksisterende historikk. Det betyr slutten på fillete og utydelige loggbøker. Og starten på en enklere hverdag',
  [AppTranslation['app-tour.content.welcome.part1']]:
    'LILO er en forkortelse for løfteinnretningslogg, og er en moderne digital loggbok for heisadministrasjon tilpasset det 21. århundre. \n' +
    'Med LILO ønsker vi å bygge verktøyet som mangler i bransjen, denne versjonen er et fundament for hva vi mener et slikt verktøy bør inneholde.',
  [AppTranslation['app-tour.content.welcome.part2']]:
    'Før du setter i gang ønsker vi å gi deg en kort introduksjon til hva du finner under de forskjellige sidene.',
  [AppTranslation.createAccount]: 'Registrer servicebruker',
  [AppTranslation['login.title']]: 'Smarte, digitale loggbøker for heis og rulletrapp',
  [AppTranslation['login.subtitle.part1']]: 'Logg inn',
  [AppTranslation['login.subtitle.part2']]: 'for å fortsette til LILO',
  [AppTranslation['login.link.forgotPassword']]: 'Har du glemt passordet ditt?',
  [AppTranslation['login.input.password.placeholder']]: 'Passord',
  [AppTranslation['login.input.username.placeholder']]: 'E-post',
  [AppTranslation['login.input.username.error.required-message']]: 'E-post er påkrevd',
  [AppTranslation['login.input.password.error.required-message']]: 'Passord er påkrevd',
  [AppTranslation['login.input.password.error.invalid-value']]: 'Må være mer enn 6 tegn',
  [AppTranslation['login.input.username.error.invalid-value']]: 'Ugyldig e-post',
  [AppTranslation.copyright]: '© {year} Norsk Heiskontroll AS',
  [AppTranslation['privacy-policy']]: 'Personvernerklæring',
  [AppTranslation['elevator.tab-name.info']]: 'Informasjon',
  [AppTranslation['elevator.tab-name.logbook']]: 'Digital loggbok',
  [AppTranslation['elevator.tab.logbook.search-input.placeholder']]: 'Søk i loggboken',
  [AppTranslation['date-input.label.from']]: 'Dato fra',
  [AppTranslation['date-input.label.to']]: 'Dato til',
  [AppTranslation['date-input.clear-button.title']]: 'Fjern',
  [AppTranslation['elevator.tab.logbook.category.title']]: 'Kategori',
  [AppTranslation['elevator.tab.logbook.subcategory.title']]: 'Underkategori',
  [AppTranslation['elevator.tab.logbook.deviation.title']]: 'Avvik',
  [AppTranslation['elevator.tab.logbook.search-input.filter-button']]: 'Legg til filter i søk',
  [AppTranslation['elevator.tab.logbook.category-select.placeholder']]: 'Velg kategori',
  [AppTranslation['elevator.tab.logbook.subcategory-select.placeholder']]: 'Velg underkategori',
  [AppTranslation['elevator.tab.logbook.text-select.placeholder']]: 'Velg forslagstekster',
  [AppTranslation['elevator.tab.logbook.signedBy-select.placeholder']]: 'Vennligst velg',
  [AppTranslation['elevator.tab.logbook.search-input.signedBy-select.label']]: 'Utført av',
  [AppTranslation['elevator.tab.logbook.table.title']]: 'Alle loggføringer',
  [AppTranslation['elevator.tab.logbook.table.header.date']]: 'Dato',
  [AppTranslation['elevator.tab.logbook.description.title']]: 'Beskrivelse',
  [AppTranslation['elevator.tab.logbook.description.title.max-length']]: ' (maks {length} tegn)',
  [AppTranslation['elevator.tab.logbook.text.title']]: 'Forslagstekster',
  [AppTranslation['elevator.tab.logbook.signedBy.title']]: 'Utført av',
  [AppTranslation['elevator.tab.logbook.createdByCompany.title']]: 'Virksomhet',
  [AppTranslation['elevator.tab.logbook.attachments.title']]: 'Vedlegg',
  [AppTranslation['elevator.tab.logbook.button.create-entry']]: 'Ny loggføring',
  [AppTranslation['elevator.tab.logbook.modal.entry-details.header.logging']]: 'Loggføring',
  [AppTranslation['elevator.tab.logbook.elevator.niNumber']]: 'Ninr: {niNumber}',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.header.new-log']]: 'Opprett ny loggføring for',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.submit-button']]: 'Send inn loggføring',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.file-upload.text.part1']]: 'Dra og slipp filer her, eller ',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.file-upload.text.part2']]: 'finn filen',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.form.description.error-msg']]: 'Beskrivelse er påkrevd',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.form.category.error-msg']]: 'Kategori er påkrevd',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.form.subCategory.error-msg']]: 'Underkategori er påkrevd',
  [AppTranslation['elevator.tab.logbook.modal.create-entry.form.elevatorId.error-msg']]: 'Heis ID er påkrevd',
  [AppTranslation['elevator.tab.logbook.table.noData']]: 'Ikke data',
  [AppTranslation['elevator.tab.logbook.modal.delete-entry.title']]: 'Slette loggføring?',
  [AppTranslation['elevator.tab.logbook.modal.delete-entry.text']]: 'Er du sikker på at du vil slette loggføringen?',
  [AppTranslation['elevator.tab.logbook.delete-entry.msg.success']]: 'Loggføring slettet',
  [AppTranslation['elevators.page-title']]: 'Dine objekter',
  [AppTranslation['elevators.page.add-new-object']]: 'Legg til nytt objekt',
  [AppTranslation['elevators.page.tab-name.all']]: 'Alle objekter',
  [AppTranslation['elevators.page.tab-name.favorites']]: 'Favoritter',
  [AppTranslation['elevators.page.tab-name.archived']]: 'Arkiverte',
  [AppTranslation['elevators.page.type']]: 'Type',
  [AppTranslation['elevators.page.niNumber']]: 'NInummer',
  [AppTranslation['elevators.page.reference']]: 'Egen referanse',
  [AppTranslation['elevators.page.last-logging']]: 'Siste loggføring',
  [AppTranslation['elevators.page.account']]: 'Eier',
  [AppTranslation['elevators.page.address']]: 'Adresse',
  [AppTranslation['elevators.page.search-input.placeholder']]: 'Søk i dine objekter',
  [AppTranslation['elevators.page.search-input.dropdown.elevator.type']]: 'Type løfteinnretning',
  [AppTranslation['elevators.page.search-input.dropdown.fabrication-number']]: 'Fabrikasjonsnummer',
  [AppTranslation['elevators.page.search-input.dropdown.fabrication-number-input.placeholder']]:
    'Søk i fabrikasjonsnummer',
  [AppTranslation['elevators.page.search-input.dropdown.account.placeholder']]: 'Søk i eier',
  [AppTranslation['elevators.page.search-input.dropdown.elevator-type-select.placeholder']]:
    'Søk i type løfteinnretning',
  [AppTranslation['elevators.page.search-input.dropdown.elevator-manufacturer-select.placeholder']]: 'Søk i produsent',
  [AppTranslation['elevators.page.search-input.dropdown.elevator-model-select.placeholder']]: 'Søk i modell',
  [AppTranslation['elevators.page.search-input.dropdown.niNumber-input.placeholder']]: 'Søk i NInummer',
  [AppTranslation['elevators.page.tab.all.text']]: 'Viser alle {quantity} objekter',
  [AppTranslation['elevators.page.tab.loaded-elevators.text']]:
    'Viser {loadedQuantity} av totalt {totalQuantity} objekter',
  [AppTranslation['elevators.page.tab.all.text.for-one-object']]: 'Viser 1 objekt',
  [AppTranslation['elevators.page.tab.all.filtering-text']]: 'Søkeresultat for {freeText}',
  [AppTranslation['elevators.page.tab.all.checked-elevators']]: '{quantity} objekter markert',
  [AppTranslation['elevators.page.tab.all.checked-elevators.for-one-object']]: '1 objekt markert',
  [AppTranslation['elevator.page.settings-button.edit-object']]: 'Rediger Objekt',
  [AppTranslation['elevator.page.settings-button.give-access']]: 'Gi tilgang',
  [AppTranslation['elevator.page.settings-button.generate-qr-code']]: 'Generer QR kode',
  [AppTranslation['elevator.page.settings-button.archive-object']]: 'Arkiver objekt',
  [AppTranslation['elevator.give-access']]: 'Gi tilgang',
  [AppTranslation['elevator.update-information']]: 'Oppdater informasjon',
  [AppTranslation['elevator.give-access.company']]: 'Gi serviceselskap tilgang',
  [AppTranslation['elevator.modal.give-access.subtitle']]: 'Tilgangsstyring',
  [AppTranslation['elevator.modal.give-access.user.title']]: 'Person',
  [AppTranslation['elevator.modal.give-access.company.title']]: 'Serviceselskap',
  [AppTranslation['elevator.modal.give-access.user.select.placeholder']]: 'Velg person',
  [AppTranslation['elevator.modal.give-access.company.select.placeholder']]: 'Velg serviceselskap',
  [AppTranslation['elevators.modal.give-access.button.title']]: 'Gi tilgang til valgte objekter',
  [AppTranslation['elevator.modal.give-access.button.title']]: 'Gi tilgang til dette objektet',
  [AppTranslation['elevators.modal.give-access.save-button.title']]: 'Lagre endringer',
  [AppTranslation['elevators.modal.give-access.company.confirm.title']]: 'Lagre endringer?',
  [AppTranslation['elevators.modal.give-access.company.confirm.content']]:
    'Du er i ferd med å gjøre endringer som enten innebærer at et nytt kunde får tilgang til heisobjekter eller oppdatering av informasjon på heisobjekter.\n\n' +
    'Er du helt sikker på at du ønsker å lagre endringene?',
  [AppTranslation['elevator.err-msg.required-elevators']]: 'Vennligst velg objekt',
  [AppTranslation['elevator.err-msg.form-field-values']]: 'Minst én verdi skal velges',
  [AppTranslation['elevator.modal.favorite.header.title']]: 'Legg til i favoritter',
  [AppTranslation['elevator.modal.favorite.header.subtitle']]: 'Favoritter',
  [AppTranslation['elevator.modal.favorite.content.select.title']]: 'Velg favoritt liste',
  [AppTranslation['elevator.modal.favorite.content.button.title']]: 'Legg objekt til liste',
  [AppTranslation['elevator.tab.information.commissioned.title']]: 'Driftsatt:',
  [AppTranslation['elevator.tab.information.last-logging.title']]: 'Siste loggføring:',
  [AppTranslation['elevator.tab.information.modernized.title']]: 'Modernisert:',
  [AppTranslation['elevator.tab.information.factory-number.title']]: 'Fabriknr:',
  [AppTranslation['elevator.tab.information.controlled.title']]: 'Kontrollert:',
  [AppTranslation['elevator.tab.information.control-and-security-services.title']]: 'Kontroll- og sikkerhetstjenester:',
  [AppTranslation['elevator.tab.information.service-agreement.title']]: 'Serviceavtale:',
  [AppTranslation['elevator.tab.information.alarm-company.title']]: 'Alarmmottak:',
  [AppTranslation['elevator.tab.information.alarm-type.title']]: 'Alarmtype:',
  [AppTranslation['elevator.tab.information.alarm-line-and-line-provider.title']]: 'Alarmlinje og linjeleverandør:',
  [AppTranslation['elevator.tab.information.alarm-unit-phone-number.title']]: 'Telefonnummer til alarmenhet:',
  [AppTranslation['elevator.tab.information.open-map-button']]: 'Åpne kart i fullvisning',
  [AppTranslation['elevators.tab.favorites.elevator.type']]: 'Type',
  [AppTranslation['elevators.tab.favorites.elevator.NInummer']]: 'NInummer',
  [AppTranslation['elevators.tab.favorites.elevator.reference']]: 'Egen referanse',
  [AppTranslation['elevators.tab.favorites.elevator.address']]: 'Adresse',
  [AppTranslation['elevators.tab.favorites.elevator.lastLogging']]: 'Siste loggføring',
  [AppTranslation['elevators.tab.favorites.button.add-favorite-list']]: 'Lag ny liste',
  [AppTranslation['elevators.tab.favorites.page-title']]: 'Favoritter',
  [AppTranslation['elevators.tab.favorites.modal.header.title']]: 'Lag ny liste',
  [AppTranslation['elevators.tab.favorites.modal.header.subtitle']]: 'Favoritter',
  [AppTranslation['elevators.tab.favorites.modal.button.title']]: 'Lag ny favoritt liste',
  [AppTranslation['elevators.tab.favorites.modal.input.name']]: 'Navn',
  [AppTranslation['elevators.tab.favorites.modal.input.placeholder']]: 'Skriv inn navn',
  [AppTranslation['elevators.tab.favorites.modal.input.error']]: 'Name er påkrevd',
  [AppTranslation['reset-password.success-message']]: 'E-post for gjenoppretting av passord er sendt til e-posten din',
  [AppTranslation['reset-password.page-title']]: 'Har du glemt passordet ditt?',
  [AppTranslation['reset-password.page-subtitle']]:
    'Hvis du har glemt passordet ditt, kan du tilbakestille det ved å skrive inn e-postadressen din nedenfor.',
  [AppTranslation['reset-password.email-input.error-msg']]: 'Ugyldig e-post',
  [AppTranslation['reset-password.email-input.placeholder']]: 'E-post',
  [AppTranslation['reset-password.submit-button']]: 'Send',
  [AppTranslation['change-password.title']]: 'Lag nytt passord',
  [AppTranslation['change-password.subtitle']]: 'Skriv inn ditt nye passord',
  [AppTranslation['change-password.input.password.placeholder']]: 'Passord',
  [AppTranslation['change-password.input.password.err-msg.required']]: 'Passord er påkrevd',
  [AppTranslation['change-password.input.password.err-msg.invalid-value']]:
    'Ugyldig passord, antall tegn må være minst 6',
  [AppTranslation['change-password.input.password-confirmation.placeholder']]: 'Bekreft passord',
  [AppTranslation['change-password.input.password-confirmation.err-msg.invalid-value']]: 'Passord stemmer ikke overens',
  [AppTranslation['change-password.submit-button']]: 'Lag nytt passord',
  [AppTranslation['change-password.request.success']]: 'Passord endret vellykket',
  [AppTranslation['change-password.external-user.success.title']]: 'Passord endret',
  [AppTranslation['change-password.external-user.success.subtitle']]:
    'Vennligst logg inn med oppdatert passord i appen',
  [AppTranslation['change-password.token.err-msg.invalid-token']]: 'Ugyldig token',
  [AppTranslation['elevator.form.tab.info.title']]: '{indexNumber}. Objektinformasjon',
  [AppTranslation['elevator.form.tab.contact.title']]: '{indexNumber}. Kontaktpersoner / Tilsynsperson',
  [AppTranslation['elevator.form.tab.control.title']]: '{indexNumber}. Kontroll, ettersyn og alarm',
  [AppTranslation['elevator.form.tab.location.title']]: '{indexNumber}. Lokasjon',
  [AppTranslation['elevator.form.address']]: 'Addresse',
  [AppTranslation['elevator.form.address.err-msg']]: 'Addresse er påkrevd',
  [AppTranslation['elevator.form.alarm-company.placeholder']]: 'Velg alarmmottak',
  [AppTranslation['elevator.form.alarm-type.placeholder']]: 'Velg alarmtype',
  [AppTranslation['elevator.form.alarm-line-type.placeholder']]: 'Velg type alarmlinje',
  [AppTranslation['elevator.form.fabrication-number.err-msg']]: 'Fabrikasjonsnummer er påkrevd',
  [AppTranslation['elevator.form.fabrication-year.err-msg.required']]: 'Byggår er påkrevd',
  [AppTranslation['elevator.form.fabrication-year.err-msg.wrong-value']]: 'Byggår er feil',
  [AppTranslation['elevator.form.model.err-msg']]: 'Modell er påkrevd',
  [AppTranslation['elevator.form.model.placeholder']]: 'Velg modell',
  [AppTranslation['elevator.form.manufacturer.err-msg']]: 'Produsent er påkrevd',
  [AppTranslation['elevator.form.manufacturer.placeholder']]: 'Velg produsent',
  [AppTranslation['elevator.form.type.err-msg']]: 'Type løfteinnretning er påkrevd',
  [AppTranslation['elevator.form.type.placeholder']]: 'Velg type løfteinnretning',
  [AppTranslation['elevator.form.reference']]: 'Egen referanse (Feks. Plasseringen i bygget)',
  [AppTranslation['elevator.form.reference.err-msg']]: 'Egen referanse (Feks. Plasseringen i bygget) er påkrevd',
  [AppTranslation['elevator.form.location-map']]: 'Last opp planløsning / plasseringskart',
  [AppTranslation['elevator.form.renovation-year.err-msg.wrong-value']]: 'Årstall er feil',
  [AppTranslation['elevator.form.private-room-elevator-access']]: 'Tilgang til heis gjennom private rom?',
  [AppTranslation['elevator.form.ni-number.err-msg']]: 'Ninummer er påkrevd',
  [AppTranslation['elevator.form.safety-inspection-body.err-msg']]: 'Sikkerhetskontrollorgan er påkrevd',
  [AppTranslation['elevator.form.safety-inspection-body.placeholder']]: 'Velg sikkerhetskontrollorgan',
  [AppTranslation['elevator.form.inspection-company.placeholder']]: 'Velg ettersynsfirma',
  [AppTranslation['elevator.form.inspection-company.err-msg']]: 'Ettersynsfirma er påkrevd',
  [AppTranslation['elevator.form.phone']]: 'Telefonnummer til alarmenhet',
  [AppTranslation['elevator.form.mazemap-url']]: 'Mazemap URL (Innendørs kartvisning)',
  [AppTranslation['elevator.form.mazemap-url.err-msg']]: 'Ugyldig URL',
  [AppTranslation['elevator.form.cancel-button']]: 'Avbryt',
  [AppTranslation['elevator.form.next-step-button']]: 'Neste steg',
  [AppTranslation['elevator.form.submit-button']]: 'Legg til objekt',
  [AppTranslation['elevator.form.line-provider.placeholder']]: 'Velg linjeleverandør',
  [AppTranslation['elevator.form.radio-button.yes']]: 'Ja',
  [AppTranslation['elevator.form.radio-button.no']]: 'Nei',
  [AppTranslation['elevator.form.upload-button']]: 'Velg fil',
  [AppTranslation['elevator.form.search-user-select.loading']]: 'Laster...',
  [AppTranslation['elevator.form.search-user-select.no-data']]: 'ingen valgmuligheter',
  [AppTranslation['elevator.form.contact-person.name']]: 'Navn',
  [AppTranslation['elevator.form.contact-person.mobile']]: 'Mobil',
  [AppTranslation['elevator.form.contact-person.mail']]: 'Epost',
  [AppTranslation['elevator.form.contact-person.role']]: 'Rolle',
  [AppTranslation['elevator.form.search-user-select.title']]: 'Personer lagt til som kontaktperson',
  [AppTranslation['elevator.form.contact-person.button.remove']]: 'Fjern',
  [AppTranslation['elevator.form.err-msg.required-fields']]: 'Vennligst fyll ut alle obligatoriske felter',
  [AppTranslation['edit-elevator.page.title']]: 'Rediger Objekt',
  [AppTranslation['edit-elevator.form.submit-button']]: 'Rediger objekt',
  [AppTranslation['save-elevator.form.submit-button']]: 'Lagre objekt',
  [AppTranslation['edit-elevator.form.success-msg']]: 'Operasjonen var vellykket',
  [AppTranslation['edit-elevator.form.success-msg.location-map']]: 'Plasseringskartet er oppdatert',
  [AppTranslation['elevator.form.tab.summary.title']]: '{indexNumber}. Oppsummering',
  [AppTranslation['elevator.form.tab.summary.block.object-info.title']]: 'Objektinformasjon',
  [AppTranslation['elevator.form.tab.summary.block.contact-person.title']]: 'Kontaktperson / Tilsynsperson',
  [AppTranslation['elevator.form.tab.summary.block.control.title']]: 'Kontroll, ettersyn og alarm',
  [AppTranslation['elevator.form.tab.summary.block.location.title']]: 'Lokasjon',
  [AppTranslation['elevator.address']]: 'Fakturaadresse',
  [AppTranslation['elevator.alarm-company']]: 'Alarmmottak',
  [AppTranslation['elevator.alarm-type']]: 'Alarmtype',
  [AppTranslation['elevator.alarm-line-type']]: 'Type alarmlinje',
  [AppTranslation['elevator.fabrication-number']]: 'Fabrikasjonsnummer',
  [AppTranslation['elevator.fabrication-year']]: 'Byggår',
  [AppTranslation['elevator.model']]: 'Modell',
  [AppTranslation['elevator.manufacturer']]: 'Produsent',
  [AppTranslation['elevator.type']]: 'Type løfteinnretning',
  [AppTranslation['elevator.reference']]: 'Egen referanse',
  [AppTranslation['elevator.location-map']]: 'Planløsning',
  [AppTranslation['elevator.renovation-year']]: 'Modernisert',
  [AppTranslation['elevator.private-room-elevator-access']]: 'Tilgang til heis gjennom private rom?',
  [AppTranslation['elevator.ni-number']]: 'Ninummer',
  [AppTranslation['elevator.contact-person']]: 'Kontaktperson',
  [AppTranslation['elevator.safety-inspection-body']]: 'Sikkerhetskontrollorgan',
  [AppTranslation['elevator.inspection-company']]: 'Ettersynsfirma',
  [AppTranslation['elevator.phone']]: 'Telefonnummer til alarmenhet',
  [AppTranslation['elevator.mazemap-url']]: 'Mazemap URL',
  [AppTranslation['elevator.line-provider']]: 'Linjeleverandør',
  [AppTranslation['user.first-name']]: 'Fornavn',
  [AppTranslation['user.last-name']]: 'Etternavn',
  [AppTranslation['user.telephone-number']]: 'Telefon',
  [AppTranslation['user.email']]: 'E-post',
  [AppTranslation['user.role']]: 'Rolle',
  [AppTranslation['user.password']]: 'Passord',
  [AppTranslation['user.organization']]: 'Organisasjon',
  [AppTranslation['user.logged-in']]: 'Logget inn',
  [AppTranslation['user.initials']]: 'Initialer',
  [AppTranslation['user.employee-number']]: 'Ansattnummer',
  [AppTranslation['user.confirmPassword']]: 'Bekreft passord',
  [AppTranslation['user.first-name.error-message.required']]: 'Fornavn er påkrevd',
  [AppTranslation['user.last-name.error-message.required']]: 'Etternavn er påkrevd',
  [AppTranslation['user.company-name.error-message.required']]: 'Organisasjon er påkrevd',
  [AppTranslation['user.telephone-number.error-message.required']]: 'Telefonnummer er påkrevd',
  [AppTranslation['user.email.error-message.required']]: 'E-post er påkrevd',
  [AppTranslation['user.role.error-message.required']]: 'Rolle er påkrevd',
  [AppTranslation['user.initials.error-message.required']]: 'Initialer er påkrevd',
  [AppTranslation['user.employee-number.error-message.required']]: 'Ansattnummer er påkrevd',
  [AppTranslation['select.no-options']]: 'Ingen valgmuligheter',
  [AppTranslation['settings.page-title']]: 'Innstillinger',
  [AppTranslation['settings.tab.userManagement']]: 'Brukerhåndtering',
  [AppTranslation['settings.tab.companies']]: 'Tilgang til serviceselskap',
  [AppTranslation['settings.tab.invoiceInformation']]: 'Fakturainformasjon',
  [AppTranslation['settings.tab.productsSubscription']]: 'Abonnement og produkt',
  [AppTranslation['settings.tab.productsSubscription.product.name']]: 'Produktnavn',
  [AppTranslation['settings.tab.productsSubscription.product.price']]: 'Pris',
  [AppTranslation['settings.tab.productsSubscription.product.quantity']]: 'Antall',
  [AppTranslation['settings.tab.productsSubscription.product.discount']]: 'Rabatt',
  [AppTranslation['settings.tab.productsSubscription.product.subscription-start-date']]: 'Startdato for abonnement',
  [AppTranslation['settings.tab.productsSubscription.product.invoicing-start-date']]: 'Startdato for fakturering',
  [AppTranslation['settings.tab.productsSubscription.product.renewal-date']]: 'Dato for fornyelse',
  [AppTranslation['settings.tab.productsSubscription.product.total-price']]: 'Totalpris',
  [AppTranslation['settings.tab.userManagement.search-input.placeholder']]: 'Brukersøk på fornavn og e-post',
  [AppTranslation['settings.tab.userManagement.content-title']]: 'Brukere',
  [AppTranslation['settings.tab.userManagement.user-titles.name']]: 'Navn',
  [AppTranslation['settings.tab.userManagement.user-titles.email']]: 'E-post',
  [AppTranslation['settings.tab.userManagement.user-titles.phone']]: 'Telefon',
  [AppTranslation['settings.tab.userManagement.user-titles.role']]: 'Rolle',
  [AppTranslation['settings.tab.userManagement.user-titles.status']]: 'Status',
  [AppTranslation['settings.tab.userManagement.user-titles.status.active']]: 'Aktiv',
  [AppTranslation['settings.tab.userManagement.user-titles.status.inactive']]: 'Inaktiv',
  [AppTranslation['settings.tab.userManagement.button.create-user']]: 'Legg til ny bruker',
  [AppTranslation['user.deactivate.msg.success']]: 'Brukeren er deaktivert',
  [AppTranslation['user.activate.msg.success']]: 'Bruker er aktivert',
  [AppTranslation['user.delete.err-msg.required-fields']]: 'Vennligst velg en bruker',
  [AppTranslation['settings.tab.userManagement.data-title']]: 'Brukere',
  [AppTranslation['settings.tab.userManagement.modal.delete-user.title']]: 'Slette bruker?',
  [AppTranslation['settings.tab.userManagement.modal.delete-user.text']]: 'Er du sikker på at du vil slette brukeren?',
  [AppTranslation['settings.tab.userManagement.modal.create-user.title']]: 'Legg til ny bruker',
  [AppTranslation['settings.tab.userManagement.modal.create-user.subtitle']]: 'Brukerhåndtering',
  [AppTranslation['settings.tab.userManagement.modal.create-user.button.title']]: 'Opprett ny bruker',
  [AppTranslation['settings.tab.userManagement.modal.edit-user.title']]: 'Rediger bruker',
  [AppTranslation['settings.tab.userManagement.modal.edit-user.button.title']]: 'Lagre endringer',
  [AppTranslation['settings.tab.userManagement.modal.edit-user.success']]: 'Rollen ble oppdatert',
  [AppTranslation['settings.tab.userManagement.modal.edit-user-role.text']]:
    'Du gir nå {userName} rollen som kontoeier for denne kontoen. Ved å bekrefte vil din brukers rolle endres fra Kontoeier til Driftssjef / Driftsansvarlig. Vennligst bekreft.',
  [AppTranslation['settings.tab.userManagement.modal.edit-user-role.title']]: 'Lagre endringer?',
  [AppTranslation['button.delete.title']]: 'Ja, slett',
  [AppTranslation['button.cancel.title']]: 'Avbryt',
  [AppTranslation['email.error-message.invalid-value']]: 'E-post er feil',
  [AppTranslation['settings.tab.userManagement.modal.edit-user.activate-user-button.title']]: 'Aktiver bruker',
  [AppTranslation['settings.tab.userManagement.modal.edit-user.deactivate-user-button.title']]: 'Deaktiver bruker',
  [AppTranslation['user.delete.msg.success']]: 'Bruker slettet',
  [AppTranslation['login-link.title']]: 'Gå tilbake til innloggingssiden',
  [AppTranslation['profile.page-title']]: 'Din profil',
  [AppTranslation['profile.edit-modal.title']]: 'Rediger profil',
  [AppTranslation['profile.edit-modal.subtitle']]: 'Din profil',
  [AppTranslation['user.change-password']]: 'Bytt passord',
  [AppTranslation['profile.change-password.modal.old-password']]: 'Gammelt passord',
  [AppTranslation['profile.change-password.modal.new-password']]: 'Nytt passord',
  [AppTranslation['profile.change-password.modal.repeat-password']]: 'Gjenta passord',
  [AppTranslation['profile.change-password.modal.input.old-password.err-msg']]: 'Ugyldig gammelt passord',
  [AppTranslation['profile.change-password.modal.input.new-password.err-msg']]:
    'Ugyldig nytt passord, antall tegn skal være minst 6',
  [AppTranslation['profile.change-password.modal.input.repeat-password.err-msg']]: 'Passord stemmer ikke overens',
  [AppTranslation['profile.avatar.button.upload-image']]: 'Last opp nytt bilde',
  [AppTranslation['profile.avatar.button.delete-image']]: 'Slett',
  [AppTranslation['profile.avatar.upload.error']]: 'Last opp et bilde',
  [AppTranslation['profile.avatar.upload.success']]: 'Profilbildet er oppdatert',
  [AppTranslation['profile.avatar.delete.success']]: 'Profilbilde slettet',
  [AppTranslation['archive-elevators.modal.button.confirm']]: 'Ja, arkivere objekter',
  [AppTranslation['archive-elevators.modal.content']]: 'Er du sikker på at du ønsker å arkivere objekter?',
  [AppTranslation['archive-elevators.modal.header']]: 'Arkivere objekter?',
  [AppTranslation['archive-elevator.modal.button.confirm']]: 'Ja, arkivere objekt',
  [AppTranslation['archive-elevator.modal.content']]: 'Er du sikker på at du ønsker å arkivere dette objektet?',
  [AppTranslation['archive-elevator.modal.header']]: 'Arkivere objekt?',
  [AppTranslation['un-archive-elevators.modal.button.confirm']]: 'Ja, av-arkivere objekter',
  [AppTranslation['un-archive-elevators.modal.content']]: 'Er du sikker på at du vil av-arkivere objekter?',
  [AppTranslation['un-archive-elevators.modal.header']]: 'Av-arkivere objekter?',
  [AppTranslation['un-archive-elevator.modal.button.confirm']]: 'Ja, av-arkivere objekt',
  [AppTranslation['un-archive-elevator.modal.content']]: 'Er du sikker på at du ønsker å av-arkivere dette objektet?',
  [AppTranslation['un-archive-elevator.modal.header']]: 'Av-arkivere objekt?',
  [AppTranslation['elevators.tab.archived.data-title']]: 'Arkiverte objekter',
  [AppTranslation['cancel']]: 'Avbryt',
  [AppTranslation['success.message']]: 'Operasjon vellykket',
  [AppTranslation['elevator.archived-elevator-message']]:
    'Dette objektet er satt inaktivt. For å låse den opp må du {button}.',
  [AppTranslation['elevator.un-archive-elevator-button.title']]: 'klikke her',
  [AppTranslation['account.billingCompanyName']]: 'Kundenavn',
  [AppTranslation['account.billingOrganizationNumber']]: 'Organisasjonsnummer',
  [AppTranslation['account.billingAddress']]: 'Fakturaadresse',
  [AppTranslation['account.billingContactName']]: 'Kontaktperson',
  [AppTranslation['account.billingEmail']]: 'Epost for faktura',
  [AppTranslation['account.invoiceReference']]: 'Fakturareferanse',
  [AppTranslation['account.membershipType']]: 'Abonnementstype',
  [AppTranslation['error-message.required']]: 'Dette feltet er påkrevd',
  [AppTranslation['error-message.invalid-email']]: 'E-post er ugyldig',
  [AppTranslation['save']]: 'Lagre',
  [AppTranslation['logout.modal.buttons.confirm']]: 'Ja',
  [AppTranslation['logout.modal.header']]: 'Logg ut',
  [AppTranslation['logout.modal.content']]: 'Er du sikker på at du vil logge ut av kontoen din?',
  [AppTranslation['elevators.tab.favorites.modal.edit-delete-list.title']]: 'Rediger liste',
  [AppTranslation['save-changes']]: 'Lagre endringer',
  [AppTranslation['elevators.tab.favorites.modal.edit-delete-list.delete-button']]: 'Slett liste',
  [AppTranslation['select-value']]: 'Velg en verdi',
  [AppTranslation['settings.back-button']]: 'Tilbake',
  [AppTranslation['settings.search-input.placeholder']]: 'Søk etter objekt',
  [AppTranslation['settings.user.table.title']]: 'Har tilgang til følgende objekter',
  [AppTranslation['yes']]: 'Ja',
  [AppTranslation['remove-access-modal.users.header']]: 'Fjern tilgang',
  [AppTranslation['remove-access-to-elevators-modal.users.content']]:
    'Er du sikker på at du vil fjerne brukerens tilgang til de valgte objektene?',
  [AppTranslation['remove-access-to-elevator-modal.users.content']]:
    'Er du sikker på at du vil fjerne brukerens tilgang til det valgte objektet?',
  [AppTranslation['user.last-logged-in']]: 'Logget inn',
  [AppTranslation['create-external-user.title']]: 'Registrer servicebruker',
  [AppTranslation['create-external-user.subtitle']]: 'Kom i gang med LILO ved å opprette konto',
  [AppTranslation['create-external-user.button']]: 'Opprett konto',
  [AppTranslation['create-external-user.send-email-part.subtitle']]:
    'For å få tilgang til Lilo digital loggbok kan du oppgi jobb-e-posten din. Dette gjelder for deg som behøver tilgang til heiser og rullertrapper knyttet til ditt servicefirma.',
  [AppTranslation['create-external-user.send-email-part.text-with-link']]:
    'Dersom du er kontoeier og ønsker å opprette abonnement, {link}',
  [AppTranslation['create-external-user.send-email-part.link.title']]: 'trykk her',
  [AppTranslation['create-external-user.send-email-part.button']]: 'Send instrukser',
  [AppTranslation['create-external-user.send-email-part.success-message']]:
    'Instruksjoner for å opprette en konto er sendt til e-posten {email}!',
  [AppTranslation['create-external-user.send-email-part.email-error']]: 'E-post er nødvendig!',
  [AppTranslation['create-external-user.form.title']]: 'Fyll inn nødvendig informasjon i feltene under',
  [AppTranslation['create-external-user.form.subtitle']]: 'Kontoen du oppretter gjelder for e-posten {email}',
  [AppTranslation['create-external-user.form.privacyPolicy']]: 'personvernerklæringen',
  [AppTranslation['create-external-user.form.terms']]: 'bruksvilkårene',
  [AppTranslation['create-external-user.form.terms-policy-confirmation']]:
    'Ved å klikke Opprett konto, indikerer du at du har lest og godtar {terms} og {privacyPolicy}.',
  [AppTranslation['create-external-user.success-part.heading']]: 'Kontoen din er opprettet',
  [AppTranslation['create-external-user.success-part.title']]: 'Vennligst installer appen Lilo - Digital Loggbok',
  [AppTranslation['create-external-user.success-part.subtitle']]: 'Logg inn med ditt brukernavn og passord',
  [AppTranslation['create-external-user.success-part.block.title']]: 'Last ned appen',
  [AppTranslation['create-external-user.success-part.block.subtitle']]:
    'Gå til innlogginssiden for å logge inn og begynne å bruke kontoen din',
  [AppTranslation['create-external-user.success-part.login-link']]: 'Gå til innloggingssiden',
  [AppTranslation['create-external-user.token-error']]: 'Ugyldig token',
  [AppTranslation['settings.tab.companies.access-modal.title']]: 'Gi tilgang til serviceselskap',
  [AppTranslation['settings.tab.companies.access-modal.input-label']]: 'Velg serviceselskap',
  [AppTranslation['settings.tab.companies.data-title']]: 'Serviceselskap med tilgang',
  [AppTranslation['company.name']]: 'Navn',
  [AppTranslation['company.role']]: 'Rolle',
  [AppTranslation['company.emailMask']]: 'E-postdomene',
  [AppTranslation['company.email']]: 'E-post',
  [AppTranslation['company.address']]: 'Adresse',
  [AppTranslation['company.organizationNumber']]: 'Organisasjonsnummer',
  [AppTranslation['company.lastLoginAt']]: 'Logget inn',
  [AppTranslation['settings.tab.companies.search-input.placeholder']]: 'Søk etter serviceselskap',
  [AppTranslation['settings.tab.companies.modal.remove-access.header']]: 'Fjern tilgang',
  [AppTranslation['settings.tab.companies.modal.remove-access.text']]:
    'Er du sikker på at du vil fjerne serviceselskapets tilgang til kontoen din?',
  [AppTranslation['remove-access-to-elevators-modal.company.content']]:
    'Vil du virkelig fjerne serviceselskapets tilgang til de valgte objektene?',
  [AppTranslation['remove-access-to-elevator-modal.company.content']]:
    'Er du sikker på at du vil fjerne serviceselskapets tilgang for dette objektet?',
  [AppTranslation['deleted-company-tooltip.title']]: '{entityName} ikke tilgjengelig',
  [AppTranslation['deleted-company-tooltip.text1']]: '{entityName} er ikke lenger tilgjengelig i systemet.',
  [AppTranslation['deleted-company-tooltip.text2']]: 'Ved å redigere objektet kan du velge nytt {entityName}.',
  [AppTranslation['deleted-company.warn-msg']]: 'Firmaet du valgte er ikke lenger tilgjengelig, velg et annet',
  [AppTranslation['deleted-company.message']]: 'Ikke tilgjengelig',
  [AppTranslation['tooltip.archive-object']]: 'arkivere et objekt',
  [AppTranslation['tooltip.unarchive-object']]: 'av-arkivere et objekt',
  [AppTranslation['tooltip.add-company-access-to-objects']]: 'gi serviceselskap tilgang til objektet',
  [AppTranslation['tooltip.add-user-access-to-objects']]: 'legge til brukertilgang til objekter',
  [AppTranslation['tooltip.add-to-favorites']]: 'legg til i favoritter',
  [AppTranslation['tooltip.remove-from-favorites']]: 'fjern fra favoritter',
  [AppTranslation['tooltip.settings']]: 'innstillinger',
  [AppTranslation['tooltip.remove-access-to-objects']]: 'fjerne tilgang til objekter',
  [AppTranslation['tooltip.edit-favorite-list']]: 'rediger/slett liste',
  [AppTranslation['activities.content-title']]: 'Siste aktiviteter',
  [AppTranslation['activities.page-title']]: 'Velkommen, {userName}',
  [AppTranslation['activities.types.new-object.text']]: 'Opprettet et nytt objekt',
  [AppTranslation['activities.types.new-log-entry.text']]: 'Opprettet en ny loggføring i loggboken til',
  [AppTranslation['activities.types.new-user-created.text']]: 'La til en ny bruker',
  [AppTranslation['activities.types.object-archived.text']]: 'Arkiverte et objekt',
  [AppTranslation['activities.types.object-un-archived.text']]: 'Gjenåpnet et arkivert objekt',
  [AppTranslation['activities.types.add-company-access.text']]: 'Ga tilgang til et nytt serviceselskap',
  [AppTranslation['activities.types.new-object.title']]: 'Nytt objekt opprettet',
  [AppTranslation['activities.types.new-log-entry.title']]: 'Nye loggføringer',
  [AppTranslation['activities.types.new-user-created.title']]: 'Ny bruker lagt til',
  [AppTranslation['activities.types.object-archived.title']]: 'Arkiverte et objekt',
  [AppTranslation['activities.types.object-un-archived.title']]: 'Av-arkivere et objekt',
  [AppTranslation['activities.types.add-company-access.title']]: 'Nytt serviceselskap opprettet',
  [AppTranslation['activities.types-select.placeholder']]: 'Velg aktivitetsstatus',
  [AppTranslation['activities.types-select.default-option']]: 'Alle aktiviter',
  [AppTranslation['page-not-found.title']]: 'Siden finnes ikke.',
  [AppTranslation['page-not-found.description']]:
    'Siden du prøvde å finne på denne adressen er ikke tilgjengelig.\n' +
    'Bruk en av lenkene i sidemenyen eller gå tilbake til {homePageLink}.',
  [AppTranslation['page-not-found.home-page-link']]: 'forsiden',
  [AppTranslation['user.company-name']]: 'Organisasjon',
  [AppTranslation['tooltip.deleted-user.title']]: 'Brukeren eksisterer ikke',
  [AppTranslation['tooltip.deleted-user.description']]:
    'Denne brukeren er ikke lenger tilgjengelig i systemet. For å gå videre må du slette brukeren fra tabellen.',
  [AppTranslation['elevator.form.contact-persons.err-msg.wrong-value']]:
    'Denne brukeren er ikke lenger tilgjengelig i systemet. For å gå videre må du slette brukeren fra tabellen.',
  [AppTranslation['aria-label.input.password']]: 'Passord',
  [AppTranslation['aria-label.input.confirm-password']]: 'Passordbekreftelse',
  [AppTranslation['aria-label.input.email']]: 'E-post',
  [AppTranslation['aria-label.user-image']]: 'Brukerbilde',
  [AppTranslation['aria-label.profile-image']]: 'Profilbilde',
  [AppTranslation['aria-label.button.deleted-company-info-message']]: 'Informasjonsmelding om et slettet kunde',
  [AppTranslation['aria-label.mazeMap-image']]: 'MazeMap-bilde',
  [AppTranslation['aria-label.mazeMap']]: 'MazeMap',
  [AppTranslation['aria-label.button.remove-logbook-entry-modal']]: 'Fjern en loggføring',
  [AppTranslation['aria-label.logbook-entry-file-icon']]: 'Loggføringen inneholder filer',
  [AppTranslation['aria-label.logbook-entry-image']]: 'Loggføringsbilde',
  [AppTranslation['aria-label.profile.settings-button']]: 'Innstillinger',
  [AppTranslation['aria-label.settings.remove-company-access-button']]: 'Fjern tilgangen for dette selskapet',
  [AppTranslation['aria-label.button.open-edit-invoice-info-modal']]:
    'Åpne et dialogvindu for redigering av fakturainformasjon',
  [AppTranslation['aria-label.button.edit-user']]: 'Åpne et dialogvindu for å redigere bruker',
  [AppTranslation['aria-label.settings.remove-user-access-button']]:
    'Åpne et dialogvindu for å fjerne tilgang til en bruker',
  [AppTranslation['aria-label.search-input.button.open-dropdown']]: 'Åpne filtersøkefeltet',
  [AppTranslation['aria-label.button.close-modal']]: 'Lukk dialogvinduet',
  [AppTranslation['elevators.select.inspection-company.placeholder']]: 'Søk i ettersynsfirma',
  [AppTranslation['user.re-activate.msg.success']]: 'Kontoen din er re-aktivert. Du kan logge inn som normalt.',
  [AppTranslation['account-switcher.content-title']]: 'Velg kunde',
  [AppTranslation['account-switcher.content-description']]:
    'Du har tilgang til flere kunder, vennligst velg hvilket kunde du ønsker å logge inn i.',
  [AppTranslation['account-switcher.organization-number']]: 'Organisasjonsnummer: {organizationNumber}',
  [AppTranslation['account-switcher.search-input.placeholder']]: 'Søk etter kunde',
};
