import { ICompaniesParams } from '@hooks/company';
import { FilteringRule, IQueryParameters } from '@type/api';

export const apiKeys = {
  login: ['login'] as const,
  logout: ['logout'] as const,
  resetPassword: ['resetPassword'] as const,
  changePassword: ['changePassword'] as const,
  currentUser: ['currentUser'] as const,
  newEntry: ['new-logbook-entry'],
  entries: (params?: IQueryParameters) => ['entries', params] as const,
  entryCategories: (params: any) => ['entry-categories', params] as const,
  entriesUsers: ['entriesUsers'] as const,
  entryImage: (id: string) => ['entry-image', id] as const,
  deleteEntry: ['deleteEntry'] as const,
  elevators: (params?: any) => ['elevators', params] as const,
  elevator: (id: string) => ['elevator', id] as const,
  elevatorTypes: ['elevatorTypes'] as const,
  elevatorManufacturers: ['elevatorManufacturers'] as const,
  elevatorModels: ['elevatorModels'] as const,
  elevatorAlarmLineTypes: ['elevatorAlarmLineTypes'] as const,
  elevatorAlarmTypes: ['elevatorAlarmTypes'] as const,
  elevatorLineProviders: ['elevatorLineProviders'] as const,
  createElevator: ['createElevator'] as const,
  editElevator: (id: string) => ['createElevator', id] as const,
  users: (searchParam?: string) => ['users', searchParam] as const,
  settingsUsers: (params?: IQueryParameters) => ['users', params] as const,
  companies: (params?: ICompaniesParams) => ['companies', params] as const,
  giveUserAccessToElevator: (userId: string, elevatorIds: number[]) => ['userAccess', ...elevatorIds, userId] as const,
  giveCompanyAccessToElevator: (companyId: string, elevatorIds: number[]) =>
    ['companyAccess', ...elevatorIds, companyId] as const,
  userFavoriteLists: (userId?: number) => ['favoriteLists', userId] as const,
  addToFavoriteLists: ['addToFavoriteLists'] as const,
  createFavoriteList: ['createFavoriteList'] as const,
  editFavoriteList: ['editFavoriteList'] as const,
  deleteFavoriteList: ['deleteFavoriteList'] as const,
  removeFromFavorites: ['removeFromFavorites'] as const,
  setLocationMapToElevator: ['setLocationMap'] as const,
  deactivateUser: ['deactivateUser'] as const,
  activateUser: ['activateUser'] as const,
  deleteUser: ['deleteUser'] as const,
  createUser: ['createUser'] as const,
  editUser: ['editUser'] as const,
  editUserDomainRole: ['editUserDomainRole'] as const,
  domainRoles: ['domainRoles'] as const,
  profileChangePassword: ['profileChangePassword'] as const,
  uploadProfileImage: ['uploadProfileImage'] as const,
  deleteProfileImage: ['deleteProfileImage'] as const,
  archivedElevators: (params?: IQueryParameters) => ['archivedElevators', params] as const,
  archiveElevators: ['archiveElevators'] as const,
  account: ['account'] as const,
  editAccount: ['editAccount'] as const,
  accounts: ['accounts'] as const,
  membershipTypes: ['membershipTypes'] as const,
  user: (id: string) => ['user', id] as const,
  userByEmail: ['userByEmail'] as const,
  removeUserAccessToElevators: ['removeUserAccessToElevators'] as const,
  sendEmailForCreateExternalUser: ['SendEmailForCreateExternalUser'] as const,
  createExternalUser: ['createExternalUser'] as const,
  companyTypes: ['companyTypes'] as const,
  accountCompanyAccesses: (params?: IQueryParameters) => ['AccountCompanyAccesses', params] as const,
  addAccountCompanyAccesses: ['addAccountCompanyAccesses'],
  removeAccountCompanyAccesses: ['removeAccountCompanyAccesses'],
  removeCompanyAccessToElevators: ['removeCompanyAccessToElevators'],
  company: (id: string) => ['company', id] as const,
  activityTypes: ['activityTypes'],
  dashboard: (params?: FilteringRule[]) => ['dashboard', params] as const,
  reactivateUser: ['reactivateUser'],
};
