import { pxToRem } from '@ui-kit/helpers';
import { isFunction } from '@ui-kit/helpers/validation';
import { ITextBaseProps, Text } from '@ui-kit/typography';
import React, { ChangeEvent, HTMLAttributes, useMemo } from 'react';

import { Container, FileInput, InnerValue, Label } from './PrimaryFileUpload.styles';

interface Props extends Omit<HTMLAttributes<any>, 'onChange' | 'onClick'>, Omit<ITextBaseProps, 'onChange'> {
  accept?: string;
  name?: string;
  value?: FileList | string[] | string;
  onChange?: (value?: FileList | null) => void;
  isMultiple?: boolean;
  titleButton: string;
  width?: string;
  id?: string;
  isDataInput?: boolean;
  tabIndex?: number;
  isError?: boolean;
  error?: string;
}

export const PrimaryFileUpload = React.forwardRef<any, Props>(
  (
    {
      name,
      value,
      onChange: propsOnChange,
      isMultiple,
      titleButton,
      width,
      accept = 'image/*,.pdf',
      id = 'file-input',
      isDataInput = true,
      error,
      isError: isErrorProps,
      tabIndex,
      ...props
    },
    ref,
  ) => {
    const inputRef = React.useRef<HTMLInputElement | null | undefined>(null);
    const isError = Boolean(error) || isErrorProps;

    const fontSize = pxToRem(15);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.files;
      if (isFunction(propsOnChange)) {
        propsOnChange(value);
      }
    };

    const getInputContent = useMemo(() => {
      let result: (string | File | undefined)[] = [];
      if (Array.isArray(value)) {
        result = value;
      } else if (typeof value === 'string') {
        result = [value];
      } else if (value?.[0]?.name) {
        result = Array.from(value || []);
      }
      return result.map(it => (typeof it === 'string' ? it : it?.name)).join(', ');
    }, [value]);

    const onEnter = (event: any) => {
      event.keyCode === 13 && inputRef?.current?.click();
    };

    const setRef = (e?: HTMLInputElement | null) => {
      if (typeof ref === 'function') {
        ref?.(e);
      }
      inputRef.current = e;
    };

    return (
      <div>
        <Container width={width}>
          {isDataInput && (
            <InnerValue isError={isError}>
              <Text className={'value'} fontWeight={'regular'} fontSize={fontSize} textColor={'primary'} lineClamp={1}>
                {getInputContent}
              </Text>
            </InnerValue>
          )}
          <>
            <FileInput
              ref={setRef}
              name={name}
              id={id}
              onChange={onChange}
              type="file"
              accept={accept}
              multiple={isMultiple}
            />
            <Label htmlFor={id} tabIndex={0} onKeyDown={onEnter}>
              <Text
                {...props}
                as={'span'}
                role={'button'}
                textColor={props.textColor || 'secondary'}
                fontWeight={props.fontWeight || 'bold'}
                fontSize={props.fontSize || fontSize}
                fontVariant={props.fontVariant || 'primary'}
              >
                {titleButton}
              </Text>
            </Label>
          </>
        </Container>
        {isError && (
          <Text
            fontVariant={'primary'}
            fontSize={pxToRem(12)}
            margin={'10px 0 5px 0'}
            textColor={'error'}
            fontWeight={'bold'}
          >
            {error}
          </Text>
        )}
      </div>
    );
  },
);
