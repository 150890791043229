import { Link } from 'react-router-dom';
import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

const background = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.cruelSeaGreen,
});

export const Container = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex._6};
  top: 0;
  left: 0;
  width: 100vw;
  padding: 12px 30px;
  box-sizing: border-box;
  background: ${background};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
`;

export const MenuButton = styled.button`
  flex-shrink: 0;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const LogoLink = styled(Link)`
  line-height: 0;
`;
