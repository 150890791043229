export enum AppTranslation {
  appTitle = 'appTitle',
  headTitle = 'headTitle',
  'navbar.item.overview' = 'navbar.item.overview',
  'navbar.item.objects' = 'navbar.item.objects',
  'navbar.item.settings' = 'navbar.item.settings',
  'navbar.item.FAQ' = 'navbar.item.FAQ',
  'navbar.item.logOut' = 'navbar.item.logOut',
  'navbar.user.profile-settings' = 'navbar.user.profile-settings',
  'navbar.user.change-company' = 'navbar.user.change-company',
  'navbar.user.all-companies' = 'navbar.user.all-companies',
  'app-tour.title.welcome' = 'app-tour.title.welcome',
  'app-tour.content.overview' = 'app-tour.content.overview',
  'app-tour.content.objects' = 'app-tour.content.objects',
  'app-tour.content.welcome.part1' = 'app-tour.content.welcome.part1',
  'app-tour.content.welcome.part2' = 'app-tour.content.welcome.part2',
  'app-tour.button.end-introduction' = 'app-tour.button.end-introduction',
  createAccount = 'createAccount',
  copyright = 'copyright',
  'privacy-policy' = 'privacy-policy',
  'login.title' = 'login.title',
  'login.subtitle.part1' = 'login.subtitle.part1',
  'login.subtitle.part2' = 'login.subtitle.part2',
  'login.link.forgotPassword' = 'login.link.forgotPassword',
  'login.input.password.placeholder' = 'login.input.password.placeholder',
  'login.input.username.placeholder' = 'login.input.username.placeholder',
  'login.input.username.error.required-message' = 'login.input.username.error.required-message',
  'login.input.password.error.required-message' = 'login.input.password.error.required-message',
  'login.input.password.error.invalid-value' = 'login.input.password.error.invalid-value',
  'login.input.username.error.invalid-value' = 'login.input.username.error.invalid-value',
  'elevator.tab-name.info' = 'elevator.tab-name.info',
  'elevator.tab-name.logbook' = 'elevator.tab-name.logbook',
  'elevator.tab.logbook.search-input.placeholder' = 'elevator.tab.logbook.search-input.placeholder',
  'elevator.tab.logbook.search-input.filter-button' = 'elevator.tab.logbook.search-input.filter-button',
  'elevator.tab.logbook.category-select.placeholder' = 'elevator.tab.logbook.category-select.placeholder',
  'elevator.tab.logbook.subcategory-select.placeholder' = 'elevator.tab.logbook.subcategory-select.placeholder',
  'elevator.tab.logbook.text-select.placeholder' = 'elevator.tab.logbook.text-select.placeholder',
  'elevator.tab.logbook.signedBy-select.placeholder' = 'elevator.tab.logbook.signedBy-select.placeholder',
  'elevator.tab.logbook.search-input.signedBy-select.label' = 'elevator.tab.logbook.search-input.signedBy-select.label',
  'elevator.tab.logbook.category.title' = 'elevator.tab.logbook.category.title',
  'elevator.tab.logbook.subcategory.title' = 'elevator.tab.logbook.subcategory.title',
  'elevator.tab.logbook.deviation.title' = 'elevator.tab.logbook.deviation.title',
  'elevator.tab.logbook.text.title' = 'elevator.tab.logbook.text.title',
  'elevator.tab.logbook.description.title' = 'elevator.tab.logbook.description.title',
  'elevator.tab.logbook.description.title.max-length' = 'elevator.tab.logbook.description.title.max-length',
  'elevator.tab.logbook.signedBy.title' = 'elevator.tab.logbook.signedBy.title',
  'elevator.tab.logbook.createdByCompany.title' = 'elevator.tab.logbook.createdByCompany.title',
  'elevator.tab.logbook.attachments.title' = 'elevator.tab.logbook.attachments.title',
  'elevator.tab.logbook.table.title' = 'elevator.tab.logbook.table.title',
  'elevator.tab.logbook.table.header.date' = 'elevator.tab.logbook.table.header.date',
  'elevator.tab.logbook.button.create-entry' = 'elevator.tab.logbook.button.create-entry',
  'elevator.tab.logbook.modal.entry-details.header.logging' = 'elevator.tab.logbook.modal.entry-details.header.logging',
  'elevator.tab.logbook.modal.create-entry.header.new-log' = 'elevator.tab.logbook.modal.create-entry.header.new-log',
  'elevator.tab.logbook.modal.create-entry.submit-button' = 'elevator.tab.logbook.modal.create-entry.submit-button',
  'elevator.tab.logbook.modal.create-entry.file-upload.text.part1' = 'elevator.tab.logbook.modal.create-entry.file-upload.text.part1',
  'elevator.tab.logbook.modal.create-entry.file-upload.text.part2' = 'elevator.tab.logbook.modal.create-entry.file-upload.text.part2',
  'elevator.tab.logbook.modal.create-entry.form.description.error-msg' = 'elevator.tab.logbook.modal.create-entry.form.description.error-msg',
  'elevator.tab.logbook.modal.create-entry.form.category.error-msg' = 'elevator.tab.logbook.modal.create-entry.form.category.error-msg',
  'elevator.tab.logbook.modal.create-entry.form.subCategory.error-msg' = 'elevator.tab.logbook.modal.create-entry.form.subCategory.error-msg',
  'elevator.tab.logbook.modal.create-entry.form.elevatorId.error-msg' = 'elevator.tab.logbook.modal.create-entry.form.elevatorId.error-msg',
  'date-input.label.from' = 'date-input.label.from',
  'date-input.label.to' = 'date-input.label.to',
  'date-input.clear-button.title' = 'date-input.clear-button.title',
  'elevator.tab.logbook.elevator.niNumber' = 'elevator.tab.logbook.elevator.niNumber',
  'elevator.tab.logbook.table.noData' = 'elevator.tab.logbook.table.noData',
  'elevator.tab.logbook.modal.delete-entry.title' = 'elevator.tab.logbook.modal.delete-entry.title',
  'elevator.tab.logbook.modal.delete-entry.text' = 'elevator.tab.logbook.modal.delete-entry.text',
  'elevator.tab.logbook.delete-entry.msg.success' = 'elevator.tab.logbook.delete-entry.msg.success',
  'elevators.page-title' = 'elevators.page-title',
  'elevators.page.add-new-object' = 'elevators.page.add-new-object',
  'elevators.page.tab-name.all' = 'elevators.page.tab-name.all',
  'elevators.page.tab-name.favorites' = 'elevators.page.tab-name.favorites',
  'elevators.page.tab-name.archived' = 'elevators.page.tab-name.archived',
  'elevators.page.type' = 'elevators.page.type',
  'elevators.page.niNumber' = 'elevators.page.niNumber',
  'elevators.page.reference' = 'elevators.page.reference',
  'elevators.page.address' = 'elevators.page.address',
  'elevators.page.last-logging' = 'elevators.page.last-logging',
  'elevators.page.account' = 'elevators.page.account',
  'elevators.page.search-input.placeholder' = 'elevators.page.search-input.placeholder',
  'elevators.page.search-input.dropdown.elevator.type' = 'elevators.page.search-input.dropdown.elevator.type',
  'elevators.page.search-input.dropdown.fabrication-number' = 'elevators.page.search-input.dropdown.fabrication-number',
  'elevators.page.search-input.dropdown.account.placeholder' = 'elevators.page.search-input.dropdown.account.placeholder',
  'elevators.page.search-input.dropdown.elevator-type-select.placeholder' = 'elevators.page.search-input.dropdown.elevator-type-select.placeholder',
  'elevators.page.search-input.dropdown.elevator-manufacturer-select.placeholder' = 'elevators.page.search-input.dropdown.elevator-manufacturer-select.placeholder',
  'elevators.page.search-input.dropdown.elevator-model-select.placeholder' = 'elevators.page.search-input.dropdown.elevator-model-select.placeholder',
  'elevators.page.search-input.dropdown.niNumber-input.placeholder' = 'elevators.page.search-input.dropdown.niNumber-input.placeholder',
  'elevators.page.search-input.dropdown.fabrication-number-input.placeholder' = 'elevators.page.search-input.dropdown.fabrication-number-input.placeholder',
  'elevators.page.tab.all.text' = 'elevators.page.tab.all.text',
  'elevators.page.tab.loaded-elevators.text' = 'elevators.page.tab.loaded-elevators.text',
  'elevators.page.tab.all.text.for-one-object' = 'elevators.page.tab.all.text.for-one-object',
  'elevators.page.tab.all.filtering-text' = 'elevators.page.tab.all.filtering-text',
  'elevators.page.tab.all.checked-elevators' = 'elevators.page.tab.all.checked-elevators',
  'elevators.page.tab.all.checked-elevators.for-one-object' = 'elevators.page.tab.all.checked-elevators.for-one-object',
  'elevator.page.settings-button.edit-object' = 'elevator.page.settings-button.edit-object',
  'elevator.page.settings-button.give-access' = 'elevator.page.settings-button.give-access',
  'elevator.page.settings-button.generate-qr-code' = 'elevator.page.settings-button.generate-qr-code',
  'elevator.page.settings-button.archive-object' = 'elevator.page.settings-button.archive-object',
  'elevator.give-access' = 'elevator.give-access',
  'elevator.update-information' = 'elevator.update-information',
  'elevator.give-access.company' = 'elevator.give-access.company',
  'elevator.modal.give-access.subtitle' = 'elevator.modal.give-access.subtitle',
  'elevator.modal.give-access.user.title' = 'elevator.modal.give-access.user.title',
  'elevator.modal.give-access.company.title' = 'elevator.modal.give-access.company.title',
  'elevator.modal.give-access.company.select.placeholder' = 'elevator.modal.give-access.company.select.placeholder',
  'elevator.modal.give-access.user.select.placeholder' = 'elevator.modal.give-access.user.select.placeholder',
  'elevators.modal.give-access.button.title' = 'elevators.modal.give-access.button.title',
  'elevator.modal.give-access.button.title' = 'elevator.modal.give-access.button.title',
  'elevators.modal.give-access.save-button.title' = 'elevators.modal.give-access.save-button.title',
  'elevators.modal.give-access.company.confirm.title' = 'elevators.modal.give-access.company.confirm.title',
  'elevators.modal.give-access.company.confirm.content' = 'elevators.modal.give-access.company.confirm.content',
  'elevator.err-msg.required-elevators' = 'elevator.err-msg.required-elevators',
  'elevator.err-msg.form-field-values' = 'elevator.err-msg.form-field-values',
  'elevator.modal.favorite.header.title' = 'elevator.modal.favorite.header.title',
  'elevator.modal.favorite.header.subtitle' = 'elevator.modal.favorite.header.subtitle',
  'elevator.modal.favorite.content.select.title' = 'elevator.modal.favorite.content.select.title',
  'elevator.modal.favorite.content.button.title' = 'elevator.modal.favorite.content.button.title',
  'elevator.tab.information.commissioned.title' = 'elevator.tab.information.commissioned.title',
  'elevator.tab.information.last-logging.title' = 'elevator.tab.information.last-logging.title',
  'elevator.tab.information.modernized.title' = 'elevator.tab.information.modernized.title',
  'elevator.tab.information.factory-number.title' = 'elevator.tab.information.factory-number.title',
  'elevator.tab.information.controlled.title' = 'elevator.tab.information.controlled.title',
  'elevator.tab.information.control-and-security-services.title' = 'elevator.tab.information.control-and-security-services.title',
  'elevator.tab.information.service-agreement.title' = 'elevator.tab.information.service-agreement.title',
  'elevator.tab.information.alarm-company.title' = 'elevator.tab.information.alarm-company.title',
  'elevator.tab.information.alarm-type.title' = 'elevator.tab.information.alarm-type.title',
  'elevator.tab.information.alarm-line-and-line-provider.title' = 'elevator.tab.information.alarm-line-and-line-provider.title',
  'elevator.tab.information.alarm-unit-phone-number.title' = 'elevator.tab.information.alarm-unit-phone-number.title',
  'elevator.tab.information.open-map-button' = 'elevator.tab.information.open-map-button',
  'elevators.tab.favorites.elevator.type' = 'elevators.tab.favorites.elevator.type',
  'elevators.tab.favorites.elevator.NInummer' = 'elevators.tab.favorites.elevator.NInummer',
  'elevators.tab.favorites.elevator.reference' = 'elevators.tab.favorites.elevator.reference',
  'elevators.tab.favorites.elevator.address' = 'elevators.tab.favorites.elevator.address',
  'elevators.tab.favorites.elevator.lastLogging' = 'elevators.tab.favorites.elevator.lastLogging',
  'elevators.tab.favorites.button.add-favorite-list' = 'elevators.tab.favorites.button.add-favorite-list',
  'elevators.tab.favorites.page-title' = 'elevators.tab.favorites.page-title',
  'elevators.tab.favorites.modal.header.title' = 'elevators.tab.favorites.modal.header.title',
  'elevators.tab.favorites.modal.header.subtitle' = 'elevators.tab.favorites.modal.header.subtitle',
  'elevators.tab.favorites.modal.input.name' = 'elevators.tab.favorites.modal.input.name',
  'elevators.tab.favorites.modal.input.placeholder' = 'elevators.tab.favorites.modal.input.placeholder',
  'elevators.tab.favorites.modal.input.error' = 'elevators.tab.favorites.modal.input.error',
  'elevators.tab.favorites.modal.button.title' = 'elevators.tab.favorites.modal.button.title',
  'elevators.tab.favorites.modal.edit-delete-list.title' = 'elevators.tab.favorites.modal.edit-delete-list.title',
  'elevators.tab.favorites.modal.edit-delete-list.delete-button' = 'elevators.tab.favorites.modal.edit-delete-list.delete-button',
  'reset-password.success-message' = 'reset-password.success-message',
  'reset-password.page-title' = 'reset-password.page-title',
  'reset-password.page-subtitle' = 'reset-password.page-subtitle',
  'reset-password.email-input.error-msg' = 'reset-password.email-input.error-msg',
  'reset-password.submit-button' = 'reset-password.submit-button',
  'reset-password.email-input.placeholder' = 'reset-password.email-input.placeholder',
  'change-password.title' = 'change-password.title',
  'change-password.subtitle' = 'change-password.subtitle',
  'change-password.input.password.placeholder' = 'change-password.input.password.placeholder',
  'change-password.input.password.err-msg.required' = 'change-password.input.password.err-msg.required',
  'change-password.input.password.err-msg.invalid-value' = 'change-password.input.password.err-msg.invalid-value',
  'change-password.input.password-confirmation.placeholder' = 'change-password.input.password-confirmation.placeholder',
  'change-password.input.password-confirmation.err-msg.invalid-value' = 'change-password.input.password-confirmation.err-msg.invalid-value',
  'change-password.submit-button' = 'change-password.submit-button',
  'change-password.request.success' = 'change-password.request.success',
  'change-password.external-user.success.title' = 'change-password.external-user.success.title',
  'change-password.external-user.success.subtitle' = 'change-password.external-user.success.subtitle',
  'change-password.token.err-msg.invalid-token' = 'change-password.token.err-msg.invalid-token',
  'elevator.form.tab.info.title' = 'elevator.form.tab.info.title',
  'elevator.form.tab.contact.title' = 'elevator-form.tab.contact.title',
  'elevator.form.tab.control.title' = 'elevator.form.tab.contact.title',
  'elevator.form.tab.location.title' = 'elevator.form.tab.location.title',
  'elevator.form.tab.summary.title' = 'elevator.form.tab.summary.title',
  'elevator.form.type.err-msg' = 'elevator.form.type.err-msg',
  'elevator.form.type.placeholder' = 'elevator.form.type.placeholder',
  'elevator.form.model.err-msg' = 'elevator.form.model.err-msg',
  'elevator.form.model.placeholder' = 'elevator.form.model.placeholder',
  'elevator.form.manufacturer.err-msg' = 'elevator.form.manufacturer.err-msg',
  'elevator.form.manufacturer.placeholder' = 'elevator.form.manufacturer.placeholder',
  'elevator.form.fabrication-year.err-msg.required' = 'elevator.form.fabrication-year.err-msg.required',
  'elevator.form.fabrication-year.err-msg.wrong-value' = 'elevator.form.fabrication-year.err-msg.wrong-value',
  'elevator.form.address' = 'elevator.form.address',
  'elevator.form.address.err-msg' = 'elevator.form.address.err-msg',
  'elevator.form.reference' = 'elevator.form.reference',
  'elevator.form.reference.err-msg' = 'elevator.form.reference.err-msg',
  'elevator.form.location-map' = 'elevator.form.location-map',
  'elevator.form.renovation-year.err-msg.wrong-value' = 'elevator.form.renovation-year.err-msg.wrong-value',
  'elevator.form.private-room-elevator-access' = 'elevator.form.private-room-elevator-access',
  'elevator.form.ni-number.err-msg' = 'elevator.form.ni-number.err-msg',
  'elevator.form.fabrication-number.err-msg' = 'elevator.form.fabrication-number.err-msg',
  'elevator.form.safety-inspection-body.err-msg' = 'elevator.form.safety-inspection-body.err-msg',
  'elevator.form.safety-inspection-body.placeholder' = 'elevator.form.safety-inspection-body.placeholder',
  'elevator.form.inspection-company.placeholder' = 'elevator.form.inspection-company.placeholder',
  'elevator.form.inspection-company.err-msg' = 'elevator.form.inspection-company.err-msg',
  'elevator.form.alarm-company.placeholder' = 'elevator.form.alarm-company.placeholder',
  'elevator.form.alarm-type.placeholder' = 'elevator.form.alarm-type.placeholder',
  'elevator.form.alarm-line-type.placeholder' = 'elevator.form.alarm-line-type.placeholder',
  'elevator.form.line-provider.placeholder' = 'elevator.form.line-provider.placeholder',
  'elevator.form.phone' = 'elevator.form.phone',
  'elevator.form.mazemap-url' = 'elevator.form.mazemap-url',
  'elevator.form.mazemap-url.err-msg' = 'elevator.form.mazemap-url.err-msg',
  'elevator.form.cancel-button' = 'elevator.form.cancel-button',
  'elevator.form.next-step-button' = 'elevator.form.next-step-button',
  'elevator.form.submit-button' = 'elevator.form.submit-button',
  'elevator.form.radio-button.yes' = 'elevator.form.radio-button.yes',
  'elevator.form.radio-button.no' = 'elevator.form.radio-button.no',
  'elevator.form.upload-button' = 'elevator.form.upload-button',
  'elevator.form.search-user-select.loading' = 'elevator.form.search-user-select.loading',
  'elevator.form.search-user-select.title' = 'elevator.form.search-user-select.title',
  'elevator.form.search-user-select.no-data' = 'elevator.form.search-user-select.no-data',
  'elevator.form.contact-person.name' = 'elevator.form.contact-person.name',
  'elevator.form.contact-person.mobile' = 'elevator.form.contact-person.mobile',
  'elevator.form.contact-person.mail' = 'elevator.form.contact-person.mail',
  'elevator.form.contact-person.role' = 'elevator.form.contact-person.role',
  'elevator.form.contact-person.button.remove' = 'elevator.form.contact-person.button.remove',
  'elevator.form.err-msg.required-fields' = 'elevator.form.err-msg.required-fields',
  'edit-elevator.page.title' = 'edit-elevator.page.title',
  'edit-elevator.form.submit-button' = 'edit-elevator.form.submit-button',
  'save-elevator.form.submit-button' = 'save-elevator.form.submit-button',
  'edit-elevator.form.success-msg' = 'edit-elevator.form.success-msg',
  'edit-elevator.form.success-msg.location-map' = 'edit-elevator.form.success-msg.location-map',
  'elevator.form.tab.summary.block.object-info.title' = 'elevator.form.tab.summary.block.object-info.title',
  'elevator.form.tab.summary.block.contact-person.title' = 'elevator.form.tab.summary.block.contact-person.title',
  'elevator.form.tab.summary.block.control.title' = 'elevator.form.tab.summary.block.control.title',
  'elevator.form.tab.summary.block.location.title' = 'elevator.form.tab.summary.block.location.title',
  'user.first-name' = 'user.first-name',
  'user.last-name' = 'user.last-name',
  'user.company-name' = 'user.company-name',
  'user.email' = 'user.email',
  'user.telephone-number' = 'user.telephone-number',
  'user.role' = 'user.role',
  'user.organization' = 'user.organization',
  'user.logged-in' = 'user.logged-in',
  'user.initials' = 'user.initials',
  'user.employee-number' = 'user.employee-number',
  'elevator.type' = 'elevator.type',
  'elevator.model' = 'elevator.model',
  'elevator.manufacturer' = 'elevator.manufacturer',
  'elevator.fabrication-year' = 'elevator.fabrication-year',
  'elevator.address' = 'elevator.address',
  'elevator.reference' = 'elevator.reference',
  'elevator.location-map' = 'elevator.location-map',
  'elevator.renovation-year' = 'elevator.renovation-year',
  'elevator.private-room-elevator-access' = 'elevator.private-room-elevator-access',
  'elevator.ni-number' = 'elevator.ni-number',
  'elevator.fabrication-number' = 'elevator.fabrication-number',
  'elevator.contact-person' = 'elevator.contact-person',
  'elevator.safety-inspection-body' = 'elevator.safety-inspection-body',
  'elevator.inspection-company' = 'elevator.inspection-company',
  'elevator.alarm-company' = 'elevator.alarm-company',
  'elevator.alarm-type' = 'elevator.alarm-type',
  'elevator.alarm-line-type' = 'elevator.alarm-line-type',
  'elevator.line-provider' = 'elevator.line-provider',
  'elevator.phone' = 'elevator.phone',
  'elevator.mazemap-url' = 'elevator.mazemap-url',
  'select.no-options' = 'select.no-options',
  'settings.page-title' = 'settings.page-title',
  'settings.tab.userManagement' = 'settings.tab.userManagement',
  'settings.tab.companies' = 'settings.tab.companies',
  'settings.tab.invoiceInformation' = 'settings.tab.invoiceInformation',
  'settings.tab.productsSubscription' = 'settings.tab.productsSubscription',
  'settings.tab.productsSubscription.product.name' = 'settings.tab.productsSubscription.product.name',
  'settings.tab.productsSubscription.product.price' = 'settings.tab.productsSubscription.product.price',
  'settings.tab.productsSubscription.product.quantity' = 'settings.tab.productsSubscription.product.quantity',
  'settings.tab.productsSubscription.product.discount' = 'settings.tab.productsSubscription.product.discount',
  'settings.tab.productsSubscription.product.subscription-start-date' = 'settings.tab.productsSubscription.product.subscription-start-date',
  'settings.tab.productsSubscription.product.invoicing-start-date' = 'settings.tab.productsSubscription.product.invoicing-start-date',
  'settings.tab.productsSubscription.product.renewal-date' = 'settings.tab.productsSubscription.product.renewal-date',
  'settings.tab.productsSubscription.product.total-price' = 'settings.tab.productsSubscription.product.total-price',
  'settings.tab.userManagement.search-input.placeholder' = 'settings.tab.userManagement.search-input.placeholder',
  'settings.tab.userManagement.content-title' = 'settings.tab.userManagement.content-title',
  'settings.tab.userManagement.user-titles.name' = 'settings.tab.userManagement.user-titles.name',
  'settings.tab.userManagement.user-titles.email' = 'settings.tab.userManagement.user-titles.email',
  'settings.tab.userManagement.user-titles.phone' = 'settings.tab.userManagement.user-titles.phone',
  'settings.tab.userManagement.user-titles.role' = 'settings.tab.userManagement.user-titles.role',
  'settings.tab.userManagement.user-titles.status' = 'settings.tab.userManagement.user-titles.status',
  'settings.tab.userManagement.user-titles.status.active' = 'settings.tab.userManagement.user-titles.status.active',
  'settings.tab.userManagement.user-titles.status.inactive' = 'settings.tab.userManagement.user-titles.status.inactive',
  'settings.tab.userManagement.button.create-user' = 'settings.tab.userManagement.button.create-user',
  'settings.tab.userManagement.modal.delete-user.title' = 'settings.tab.userManagement.modal.delete-user.title',
  'settings.tab.userManagement.modal.delete-user.text' = 'settings.tab.userManagement.modal.delete-user.text',
  'settings.tab.userManagement.modal.create-user.title' = 'settings.tab.userManagement.modal.create-user.title',
  'settings.tab.userManagement.modal.create-user.subtitle' = 'settings.tab.userManagement.modal.create-user.subtitle',
  'settings.tab.userManagement.modal.create-user.button.title' = 'settings.tab.userManagement.modal.create-user.button.title',
  'settings.tab.userManagement.modal.edit-user.title' = 'settings.tab.userManagement.modal.edit-user.title',
  'settings.tab.userManagement.modal.edit-user.button.title' = 'settings.tab.userManagement.modal.edit-user.button.title',
  'settings.tab.userManagement.modal.edit-user.success' = 'settings.tab.userManagement.modal.edit-user.success',
  'settings.tab.userManagement.modal.edit-user-role.text' = 'settings.tab.userManagement.modal.edit-user-role.text',
  'settings.tab.userManagement.modal.edit-user-role.title' = 'settings.tab.userManagement.modal.edit-user-role.title',
  'settings.tab.userManagement.modal.edit-user.activate-user-button.title' = 'settings.tab.userManagement.modal.edit-user.activate-user-button.title',
  'settings.tab.userManagement.modal.edit-user.deactivate-user-button.title' = 'settings.tab.userManagement.modal.edit-user.deactivate-user-button.title',
  'settings.tab.userManagement.data-title' = 'settings.tab.userManagement.data-title',
  'user.deactivate.msg.success' = 'user.deactivate.msg.success',
  'user.activate.msg.success' = 'user.activate.msg.success',
  'user.delete.msg.success' = 'user.delete.msg.success',
  'user.delete.err-msg.required-fields' = 'user.delete.err-msg.required-fields',
  'button.delete.title' = 'button.delete.title',
  'button.cancel.title' = 'button.cancel.title',
  'user.first-name.error-message.required' = 'user.first-name.error-message.required',
  'user.last-name.error-message.required' = 'user.last-name.error-message.required',
  'user.company-name.error-message.required' = 'user.company-name.error-message.required',
  'user.telephone-number.error-message.required' = 'user.telephone-number.error-message.required',
  'user.email.error-message.required' = 'user.email.error-message.required',
  'user.role.error-message.required' = 'user.role.error-message.required',
  'email.error-message.invalid-value' = 'email.error-message.invalid-value',
  'user.initials.error-message.required' = 'user.initials.error-message.required',
  'user.employee-number.error-message.required' = 'user.employee-number.error-message.required',
  'user.password' = 'user.password',
  'user.confirmPassword' = 'user.confirmPassword',
  'user.last-logged-in' = 'user.last-logged-in',
  'logout.modal.buttons.confirm' = 'logout.modal.buttons.confirm',
  'logout.modal.header' = 'logout.modal.header',
  'logout.modal.content' = 'logout.modal.content',
  'login-link.title' = 'login-link.title',
  'user.change-password' = 'user.change-password',
  'profile.page-title' = 'profile.page-title',
  'profile.edit-modal.subtitle' = 'profile.edit-modal.subtitle',
  'profile.edit-modal.title' = 'profile.edit-modal.title',
  'profile.change-password.modal.old-password' = 'profile.change-password.modal.old-password',
  'profile.change-password.modal.new-password' = 'profile.change-password.modal.new-password',
  'profile.change-password.modal.repeat-password' = 'profile.change-password.modal.repeat-password',
  'profile.change-password.modal.input.old-password.err-msg' = 'profile.change-password.modal.input.old-password.err-msg',
  'profile.change-password.modal.input.new-password.err-msg' = 'profile.change-password.modal.input.new-password.err-msg',
  'profile.change-password.modal.input.repeat-password.err-msg' = 'profile.change-password.modal.input.repeat-password.err-msg',
  'profile.avatar.button.upload-image' = 'profile.avatar.button.upload-image',
  'profile.avatar.button.delete-image' = 'profile.avatar.button.delete-image',
  'profile.avatar.upload.error' = 'profile.avatar.upload.error',
  'profile.avatar.upload.success' = 'profile.avatar.upload.success',
  'profile.avatar.delete.success' = 'profile.avatar.delete.success',
  'archive-elevators.modal.button.confirm' = 'archive-elevators.button.confirm',
  'archive-elevators.modal.header' = 'archive-elevators.modal.header',
  'archive-elevators.modal.content' = 'archive-elevators.modal.content',
  'archive-elevator.modal.button.confirm' = 'archive-elevator.button.confirm',
  'archive-elevator.modal.header' = 'archive-elevator.modal.header',
  'archive-elevator.modal.content' = 'archive-elevator.modal.content',
  'un-archive-elevators.modal.button.confirm' = 'un-archive-elevators.button.confirm',
  'un-archive-elevators.modal.header' = 'un-archive-elevators.modal.header',
  'un-archive-elevators.modal.content' = 'un-archive-elevators.modal.content',
  'un-archive-elevator.modal.button.confirm' = 'un-archive-elevator.button.confirm',
  'un-archive-elevator.modal.header' = 'un-archive-elevator.modal.header',
  'un-archive-elevator.modal.content' = 'un-archive-elevator.modal.content',
  'elevators.tab.archived.data-title' = 'elevators.tab.archived.data-title',
  'elevator.archived-elevator-message' = 'elevator.archived-elevator-message',
  'elevator.un-archive-elevator-button.title' = 'elevator.un-archive-elevator-button.title',
  'cancel' = 'cancel',
  'yes' = 'yes',
  'success.message' = 'success.message',
  'account.billingCompanyName' = 'account.billingCompanyName',
  'account.billingOrganizationNumber' = 'account.billingOrganizationNumber',
  'account.billingAddress' = 'account.billingAddress',
  'account.billingContactName' = 'account.billingContactName',
  'account.billingEmail' = 'account.billingEmail',
  'account.invoiceReference' = 'account.invoiceReference',
  'account.membershipType' = 'account.membershipType',
  'error-message.required' = 'error-message.required',
  'error-message.invalid-email' = 'error-message.invalid-email',
  'save' = 'save',
  'save-changes' = 'save-changes',
  'select-value' = 'select-value',
  'settings.back-button' = 'settings.back-button',
  'settings.search-input.placeholder' = 'settings.search-input.placeholder',
  'settings.user.table.title' = 'settings.user.table.title',
  'remove-access-modal.users.header' = 'remove-access-modal.users.header',
  'remove-access-to-elevators-modal.users.content' = 'remove-access-to-elevators-modal.users.content',
  'remove-access-to-elevator-modal.users.content' = 'remove-access-to-elevator-modal.users.content',
  'create-external-user.title' = 'create-external-user.title',
  'create-external-user.subtitle' = 'create-external-user.subtitle',
  'create-external-user.button' = 'create-external-user.button',
  'create-external-user.send-email-part.subtitle' = 'create-external-user.send-email-part.subtitle',
  'create-external-user.send-email-part.link.title' = 'create-external-user.send-email-part.link.title',
  'create-external-user.send-email-part.text-with-link' = 'create-external-user.send-email-part.text-with-link',
  'create-external-user.send-email-part.button' = 'create-external-user.send-email-part.button',
  'create-external-user.send-email-part.success-message' = 'create-external-user.send-email-part.success-message',
  'create-external-user.send-email-part.email-error' = 'create-external-user.send-email-part.email-error',
  'create-external-user.token-error' = 'create-external-user.token-error',
  'create-external-user.form.title' = 'create-external-user.form.title',
  'create-external-user.form.subtitle' = 'create-external-user.form.subtitle',
  'create-external-user.form.privacyPolicy' = 'create-external-user.form.privacyPolicy',
  'create-external-user.form.terms' = 'create-external-user.form.terms',
  'create-external-user.form.terms-policy-confirmation' = 'create-external-user.form.terms-policy-confirmation',
  'create-external-user.success-part.heading' = 'create-external-user.success-part.heading',
  'create-external-user.success-part.title' = 'create-external-user.success-part.title',
  'create-external-user.success-part.subtitle' = 'create-external-user.success-part.subtitle',
  'create-external-user.success-part.block.title' = 'create-external-user.success-part.block.title',
  'create-external-user.success-part.block.subtitle' = 'create-external-user.success-part.block.subtitle',
  'create-external-user.success-part.login-link' = 'create-external-user.success-part.login-link',
  'settings.tab.companies.access-modal.title' = 'settings.tab.companies.access-modal.title',
  'settings.tab.companies.access-modal.input-label' = 'settings.tab.companies.access-modal.input-label',
  'company.name' = 'company.name',
  'company.role' = 'company.role',
  'company.emailMask' = 'company.emailMask',
  'company.email' = 'company.email',
  'company.address' = 'company.address',
  'company.organizationNumber' = 'company.organizationNumber',
  'company.lastLoginAt' = 'company.lastLoginAt',
  'settings.tab.companies.data-title' = 'settings.tab.companies.data-title',
  'settings.tab.companies.modal.remove-access.text' = 'settings.tab.companies.modal.remove-access.text',
  'settings.tab.companies.search-input.placeholder' = 'settings.tab.companies.search-input.placeholder',
  'settings.tab.companies.modal.remove-access.header' = 'settings.tab.companies.modal.remove-access.header',
  'remove-access-to-elevators-modal.company.content' = 'remove-access-to-elevators-modal.company.content',
  'remove-access-to-elevator-modal.company.content' = 'remove-access-to-elevator-modal.company.content',
  'deleted-company-tooltip.title' = 'deleted-company-tooltip.title',
  'deleted-company-tooltip.text1' = 'deleted-company-tooltip.text1',
  'deleted-company-tooltip.text2' = 'deleted-company-tooltip.text2',
  'deleted-company.warn-msg' = 'deleted-company.warn-msg',
  'deleted-company.message' = 'deleted-company.message',
  'tooltip.archive-object' = 'tooltip.archive-object',
  'tooltip.unarchive-object' = 'tooltip.unarchive-object',
  'tooltip.add-company-access-to-objects' = 'tooltip.add-company-access-to-objects',
  'tooltip.remove-access-to-objects' = 'tooltip.remove-access-to-objects',
  'tooltip.add-user-access-to-objects' = 'tooltip.add-user-access-to-objects',
  'tooltip.add-to-favorites' = 'tooltip.add-to-favorites',
  'tooltip.remove-from-favorites' = 'tooltip.remove-from-favorites',
  'tooltip.settings' = 'tooltip.settings',
  'tooltip.edit-favorite-list' = 'tooltip.edit-favorite-list',
  'activities.page-title' = 'activities.page-title',
  'activities.content-title' = 'activities.content-title',
  'activities.types.new-object.text' = 'activities.types.new-object.text',
  'activities.types.object-archived.text' = 'activities.types.object-archived.text',
  'activities.types.object-un-archived.text' = 'activities.types.object-un-archived.text',
  'activities.types.new-log-entry.text' = 'activities.types.new-log-entry.text',
  'activities.types.new-user-created.text' = 'activities.types.new-user-created.text',
  'activities.types.add-company-access.text' = 'activities.types.add-company-access.text',
  'activities.types.new-object.title' = 'activities.types.new-object.title',
  'activities.types.object-archived.title' = 'activities.types.object-archived.title',
  'activities.types.object-un-archived.title' = 'activities.types.object-un-archived.title',
  'activities.types.new-log-entry.title' = 'activities.types.new-log-entry.title',
  'activities.types.new-user-created.title' = 'activities.types.new-user-created.title',
  'activities.types.add-company-access.title' = 'activities.types.add-company-access.title',
  'activities.types-select.placeholder' = 'activities.types-select.placeholder',
  'activities.types-select.default-option' = 'activities.types-select.default-option',
  'page-not-found.title' = 'page-not-found.title',
  'page-not-found.description' = 'page-not-found.description',
  'page-not-found.home-page-link' = 'page-not-found.home-page-link',
  'tooltip.deleted-user.title' = 'tooltip.deleted-user.title',
  'tooltip.deleted-user.description' = 'tooltip.deleted-user.description',
  'elevator.form.contact-persons.err-msg.wrong-value' = 'elevator.form.contact-persons.err-msg.wrong-value',
  'aria-label.input.password' = 'aria-label.input.password',
  'aria-label.input.confirm-password' = 'aria-label.input.confirm-password',
  'aria-label.input.email' = 'aria-label.input.email',
  'aria-label.user-image' = 'aria-label.user-image',
  'aria-label.profile-image' = 'aria-label.profile-image',
  'aria-label.button.deleted-company-info-message' = 'aria-label.button.deleted-company-info-message',
  'aria-label.mazeMap-image' = 'aria-label.mazeMap-image',
  'aria-label.mazeMap' = 'aria-label.mazeMap',
  'aria-label.button.remove-logbook-entry-modal' = 'aria-label.button.remove-logbook-entry-modal',
  'aria-label.logbook-entry-file-icon' = 'aria-label.logbook-entry-file-icon',
  'aria-label.logbook-entry-image' = 'aria-label.logbook-entry-image',
  'aria-label.profile.settings-button' = 'aria-label.profile.settings-button',
  'aria-label.settings.remove-company-access-button' = 'aria-label.settings.remove-company-access-button',
  'aria-label.settings.remove-user-access-button' = 'aria-label.settings.remove-user-access-button',
  'aria-label.button.open-edit-invoice-info-modal' = 'aria-label.button.open-edit-invoice-info-modal',
  'aria-label.button.edit-user' = 'aria-label.button.edit-user',
  'aria-label.search-input.button.open-dropdown' = 'aria-label.search-input.button.open-dropdown',
  'aria-label.button.close-modal' = 'aria-label.button.close-modal',
  'elevators.select.inspection-company.placeholder' = 'elevators.select.inspection-company.placeholder',
  'user.re-activate.msg.success' = 'user.re-activate.msg.success',
  'account-switcher.content-title' = 'account-switcher.content-title',
  'account-switcher.content-description' = 'account-switcher.content-description',
  'account-switcher.organization-number' = 'account-switcher.organization-number',
  'account-switcher.search-input.placeholder' = 'account-switcher.search-input.placeholder',
}

export type AppLocale = {
  [key in AppTranslation]: string;
};

export type TranslationKeyType = keyof typeof AppTranslation;
