import { themeMode } from '@ui-kit/theme';
import styled from 'styled-components';
import theme from 'styled-theming';

export const Container = styled.div<{ width?: string }>`
  display: inline-flex;
  align-items: stretch;
  justify-content: space-between;
  width: ${({ width }) => width || '100%'};
`;

const background = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.seaGreen,
});

export const Label = styled.label`
  position: relative;
  padding: 12px 33px;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  background: ${background};
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const FileInput = styled.input`
  visibility: hidden;
  position: absolute;
  left: -999999px;
  top: -999999px;
`;

const backgroundValue = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.white,
});

const errorBorderValue = theme('mode', {
  [themeMode.light]: ({ theme }) => theme.palette.commonColors.red,
});

export const InnerValue = styled.div<{ isError?: boolean }>`
  flex-grow: 1;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid ${props => (props?.isError ? errorBorderValue(props) : background(props))};
  background: ${backgroundValue};
  margin-right: 11px;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  word-break: break-word;
`;
